import React, { useState, FC, useContext } from 'react';
import { Auth as AmplifyAuth } from '@aws-amplify/auth';
import UserContext, {
  UserContextProps
} from '@leuven2030/framework/User/UserContext';
import { AuthState } from '@leuven2030/framework/Auth/AuthTypes';
import Auth from '@leuven2030/framework/Auth/Auth';
import ModalDialog from '@leuven2030/framework/Modal/ModalDialog';
import { useAsync } from 'react-use';
import User from '@leuven2030/framework/User/User';
import { UserProviderProps } from '@leuven2030/framework/User/UserProvider';

type Props = UserProviderProps & {
  setUserContext: (userContext: UserContextProps) => void;
};

const UserProviderDynamic: FC<Props> = ({
  setUserContext,
  override: userOverrideProps
}) => {
  const [open, setOpen] = useState(false);
  const [authState, setAuthState] = useState<
    AuthState.SignIn | AuthState.SignUp
  >(AuthState.SignIn);
  const user = userOverrideProps ? new User(userOverrideProps) : null;
  const userContext = useContext(UserContext);
  const { currentUser } = userContext;

  //TODO: Refresh token automatically when changes are made to the user
  function refreshToken() {
    const currentSession = currentUser.signInUserSession;
    currentUser.refreshSession(currentSession.refreshToken, (err, session) => {
      console.info('refreshed user token');
      console.info(err, session);
    });
  }

  const newContext = {
    user,
    isLoggedin: !!user,
    signOut: async () => {
      await AmplifyAuth.signOut();
      await reloadUserInfo();
    },
    signIn: () => {
      setOpen(true);
      setAuthState(AuthState.SignIn);
    },
    signUp: () => {
      setOpen(true);
      setAuthState(AuthState.SignUp);
    },
    reloadUserInfo,
    currentUser: null
  };

  async function reloadUserInfo() {
    const currentUser = await AmplifyAuth.currentAuthenticatedUser().catch(
      () => null
    );
    //console.log('currentUser', currentUser);
    let info: User;
    if (currentUser) {
      const {
        attributes: { sub, email_verified, email },
        signInUserSession: {
          idToken: { payload }
        }
      } = currentUser;
      info = new User({
        id: sub,
        emailVerified: email_verified,
        email,
        groups: payload['cognito:groups']
      });
    }
    setUserContext({
      ...newContext,
      ready: true,
      currentUser,
      user: info,
      isLoggedin: !!info
    });
  }

  useAsync(async () => {
    await reloadUserInfo();
  }, []);

  return (
    <ModalDialog
      open={open && !userContext.isLoggedin}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Auth initialAuthState={authState} />
    </ModalDialog>
  );
};

export default UserProviderDynamic;
