import pick from 'lodash/pick';

class User {
  id: string;
  emailVerified: boolean;
  email: string;
  groups: string[];

  data() {
    return pick(this, ['id', 'email', 'emailVerified', 'groups']);
  }

  inGroup(group) {
    return this.groups.includes(group);
  }

  constructor(props: Pick<User, 'id' | 'email' | 'emailVerified' | 'groups'>) {
    Object.assign(this, props);
  }
}

export default User;
